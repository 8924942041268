.App {
  margin: 2% 20%;
}

/* logo image */

body.main {
  background-color: #ffffff;

  /* border: rgb(18, 79, 120, 0.8) solid 1.5vw;*/
}

body {
  font-family: "Moto", serif;
  font-size: 16px;
  color: #0a085d;
  background-color: #ffffff;
}

/* hero background image */

.imageAboutPage {
  /* You can adjust the value to your preference */
  width: auto; /* To maintain the aspect ratio */
}

.overlay-home {
  position: relative;
  top: 24%;
  left: 12%;
  width: 5%;
  height: 11%;
  object-fit: 20; /* Maintain aspect ratio and cover the container */
  z-index: 1; /* Set the overlay "width: 100%; max-width: 100%; height: auto;" above the background image */
}

.overlay-head {
  position: absolute;
  top: 20%;
  left: 3%;
  width: 2%;
  height: 70%;
  object-fit: 80; /* Maintain aspect ratio and cover the container */
  z-index: 1; /* Set the overlay image above the background image */
}

/* ~~~ TEXT STYLING ~~~ */

.centered p {
  margin: 1px 0; /* Add some margin between paragraphs */
  font-family: "Moto", sans-serif;
  font-size: large;
  font-weight: 300;
}

.middle p {
  margin: 1px 0; /* Add some margin between paragraphs */
  font-family: "Moto", serif;
  text-align: center;
  font-size: large;
}

.middle p1 {
  margin: 1px 0; /* Add some margin between paragraphs */
  font-family: "URWGeometricRegular", sans-serif;
  text-align: left;
  font-size: medium;
}

.left-aligned {
  text-align: left;
  font-weight: 100;
}

.centered p1 {
  margin: 1px 0; /* Add some margin between paragraphs */
  font-family: "Moto", serif;
  font-size: medium;
  font-weight: lighter;
  justify-content: left;
}

h1.text-left {
  font-family: "Moto", serif;
  text-align: left;
  background-color: #ffc2c2;
  /* You can change the color (#000) and border width (2px) as needed */
  /* Adjust the padding to create space between the text and the border */
  display: inline-block; /* Ensures that the border wraps around the text */
  padding: 1.5%;
}

h1.text-center {
  font-family: "Moto", serif;
  text-align: center;
  font-size: 20px;
  /* You can change the color (#000) and border width (2px) as needed */
  /* Adjust the padding to create space between the text and the border */
  display: inline-block; /* Ensures that the border wraps around the text */
}

/* Centered text */
.centered {
  position: relative;
  padding-top: 90%;
  padding-bottom: 2%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
  font-family: "Moto", serif;
  text-align: center; /* Center-align the text */
}

p {
  position: relative;
  text-align: left; /* Center-align the text */
  font-family: "Moto", serif;
  color: #0a085d;
}
p3 {
  position: relative;
  text-align: left; /* Center-align the text */
  font-family: "F25", serif;
  color: #0a085d;
}
p3b {
  position: relative;
  text-align: left; /* Center-align the text */
  font-family: "F25", serif;
  font-size: xx-large;
  color: #0a085d;
}

p2 {
  position: relative;
  text-align: left; /* Center-align the text */
  font-family: "Arial", serif;
  font-size: 10px;
  color: #0a085d;
}
p1 {
  font-family: "URWGeometricRegular", sans-serif;
  font-weight: 100;
}

.btn.btn-success {
  color: #ffb2b2; /* Set the text color for the blue button */
  /* You can add more styles here if needed */
}
/* Assuming .btn-outline-secondary is the class for your button */
.btn.btn-outline-secondary:hover {
  background-color: #ffb2b2;
  color: #a7b7f4 /* Specify the text color you want on hover */;
}

/* ~~~ CONTENT CONTAINER STYLING ~~~ */

.content {
  max-width: 567px; /* Adjust as needed */
  margin: auto;
  border: #001c80 solid 1px;
  padding-inline: 5%;
}

.containermain {
  border: #001c80 solid 1px;
  padding: 8%;
  background-image: url("stamp.png");

  background-repeat: no-repeat;
  max-width: fit-content;
}

.container {
  border: #001c80 solid 1px;
  padding: 8%;

  max-width: fit-content;
}

/* ~~~ NAVBAR STYLING ~~~ */

.navbar {
  background-color: rgb(
    255,
    255,
    255
  ); /* Change to your desired background color */
  color: rgb(10, 7, 72); /* Add any other styles you want */
  font-family: "Moto", serif;
  opacity: 0.7;
  padding: 2%;
  margin-top: 1%;
  z-index: 10; /* Lower z-index than the header */
  font-size: large;

  display: flex;
  list-style: none;
  justify-content: flex-start;
}

.nav-item {
  margin-right: 10px; /* Adjust as needed for spacing between items */
  text-decoration: none;
}

.nav-item a {
  text-decoration: none;
  color: inherit; /* Inherit the color from the parent (.navbar) */
}

/* Style for visited links */
.nav-item a:visited {
  color: inherit;
}

/* footer styling */

/* spacing on all sections */
#about,
#services,
#portfolio {
  margin-top: 4rem;
  padding: 2rem;
  max-width: 1100px;
  background-color: rgb(255, 255, 255);
}

* {
  box-sizing: border-box;
}

/* social media icons styling */
.social-icons {
  font-size: 25px;
  cursor: pointer;
  text-align: center;
}
.social-icons:hover {
  color: #ffb2b2;
}

/* additional styling for positioning */
.d-flex.justify-content-center {
  position: relative;
  padding-top: 10%;
}

.fab,
.fa-solid {
  color: #092c79;
}
.fab:hover,
.fa-solid:hover {
  color: #ffb2b2;
}

/* ~~~ SLIDESHOW CSS ~~~ */
/* Slideshow container */

.slideshow-container {
  max-width: 1100px;
  position: relative;
  margin: 25px;
}

.slide-container {
  overflow: auto;
  white-space: nowrap;
}

/* Hide the images by default */
.mySlides {
  display: block;
}

/* Next & previous buttons */
.prevR,
.nextR {
  cursor: pointer;
  position: rel;
  top: 40%;
  padding: 3px;
  padding-left: 8px;
  padding-right: 8px;
  color: rgb(40, 0, 202);
  font-weight: bold;
  font-size: 12px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  background-color: #ffb2b2;
  z-index: 2; /* Ensure buttons appear above images */
}

/* Position the "next button" to the right */
.nextR {
  border-radius: 3px 0 0 3px;
}

.nextA {
  color: rgb(40, 0, 202);
  background-color: #ffc2c2;
  padding: 3px;
  padding-right: 9px;
  padding-left: 9px;

  border-radius: 3px 0 0 3px;
  user-select: none;
}

.nextA:hover {
  background-color: rgba(93, 150, 255, 0.811);
}

/* On hover, add a black background color with a little bit see-through */
.prevR:hover,
.nextR:hover {
  background-color: rgba(93, 150, 255, 0.811);
}

/* Caption text */
.text {
  color: rgb(8, 0, 84);
  font-size: 15px;
  font-weight: normal;
  padding: 8px 16px;
  position: relative;
  bottom: 0px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 7px;
  width: 7px;
  margin: 0 4px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.swipe {
  overflow: auto;
  white-space: nowrap;
}

.active,
.dot:hover {
  background-color: rgb(112, 162, 255);
}

@font-face {
  font-family: "Chobani";
  src: url("fonts/Chobani.ttf") format("truetype"); /* Specify the correct path to your font file */

  /* Add other font formats as needed */
}

@font-face {
  font-family: "Apercu";
  src: url("fonts/Apercu.otf") format("OpenType");
}

@font-face {
  font-family: "URWGeometricHeavy";
  src: url("fonts/URWGeometricHeavy.otf") format("OpenType");
}

@font-face {
  font-family: "URWGeometricSemiBold";
  src: url("fonts/URWGeometricSemiBold.otf") format("OpenType");
}

@font-face {
  font-family: "Moto";
  src: url("fonts/Moto.otf") format("OpenType");
}

@font-face {
  font-family: "URWGeometricRegular";
  src: url("fonts/URWGeometricRegular.otf") format("OpenType");
}

@font-face {
  font-family: "F25";
  src: url("fonts/f25.otf") format("OpenType");
}
